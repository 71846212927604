var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: `SANDBOX_FORM_${_vm.id}` },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            scrollable: "",
          },
          on: { hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ cancel }) {
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _vm.creating
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            size: "sm",
                            variant: "success",
                            disabled: "",
                          },
                        },
                        [
                          _c("b-spinner", {
                            attrs: { small: "", type: "grow" },
                          }),
                          _vm._v(_vm._s(_vm.$t(_vm.inProgressLabel)) + " "),
                        ],
                        1
                      )
                    : _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "success" },
                          on: { click: _vm.ok },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.ok")))]
                      ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              on: { dismissed: _vm.dismissAlert },
              model: {
                value: _vm.errorShow,
                callback: function ($$v) {
                  _vm.errorShow = $$v
                },
                expression: "errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: _vm.$t("sandbox.name"), "label-for": "name" } },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { required: true },
                        expression: "{ required: true }",
                      },
                    ],
                    attrs: {
                      id: "name",
                      type: "text",
                      "data-vv-as": _vm.$t("sandbox.name"),
                      "data-vv-name": "name",
                      maxlength: _vm.maxNameLength,
                      "data-vv-delay": "500",
                      readonly: _vm.isReadOnly,
                      autofocus: true,
                      state: _vm.fieldValidateUtil.stateValidate(
                        _vm.isReadOnly,
                        _vm.veeFields,
                        _vm.errors,
                        "name"
                      ),
                      trim: "",
                    },
                    nativeOn: {
                      keydown: function ($event) {
                        return _vm.keydownHandler.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                ],
                1
              ),
              _vm.createdDate !== null
                ? _c("label", { staticClass: "mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("sandbox.created"))),
                  ])
                : _vm._e(),
              _vm.createdDate !== null
                ? _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: { type: "text", readonly: "" },
                        model: {
                          value: _vm.createdDate,
                          callback: function ($$v) {
                            _vm.createdDate = $$v
                          },
                          expression: "createdDate",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.sharing
                ? _c(
                    "b-row",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "b-col",
                        [
                          _c("label", { staticClass: "d-block" }, [
                            _vm._v(_vm._s(_vm.$t("dataview.field.sharing"))),
                          ]),
                          _c(
                            "b-button",
                            {
                              staticClass: "sharing-members d-inline-block",
                              attrs: { size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.editSharingMembers()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("button.members")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c("label", { staticClass: "d-block" }, [
                            _vm._v(_vm._s(_vm.$t("sandbox.editing"))),
                          ]),
                          _c(
                            "b-button",
                            {
                              staticClass: "sharing-members d-inline-block",
                              attrs: { size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.editPermissions()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("button.members")))]
                          ),
                        ],
                        1
                      ),
                      _vm.showOwner
                        ? _c(
                            "b-col",
                            [
                              _c("label", { staticClass: "d-block" }, [
                                _vm._v(_vm._s(_vm.$t("sandbox.owner"))),
                              ]),
                              _c(
                                "b-button",
                                {
                                  staticClass: "sharing-members d-inline-block",
                                  attrs: { size: "sm" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editOwner()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("button.owner")))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-invalid-feedback",
                {
                  staticClass: "alert-danger form-field-alert",
                  class: { "d-block": _vm.showNameError },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-exclamation"] },
                  }),
                  _vm._v("  " + _vm._s(_vm.errors.first("name")) + " "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("MembersModal", {
        attrs: {
          show: _vm.showSharing,
          members: _vm.sharingMembers.join(","),
          title: _vm.$t("dataview.select_members_title"),
        },
        on: {
          "update:show": function ($event) {
            _vm.showSharing = $event
          },
          success: _vm.membersSelectOk,
        },
      }),
      _c("MembersModal", {
        attrs: {
          show: _vm.showPermissions,
          members: _vm.editingPermissions.join(","),
          title: _vm.$t("dataview.select_members_editing_title"),
        },
        on: {
          "update:show": function ($event) {
            _vm.showPermissions = $event
          },
          success: _vm.permissionsSelectOk,
        },
      }),
      _c("MembersModal", {
        attrs: {
          show: _vm.showOwnerPrompt,
          multiple: false,
          members: _vm.owner,
          title: _vm.$t("sandbox.select_owner_editing_title"),
        },
        on: {
          "update:show": function ($event) {
            _vm.showOwnerPrompt = $event
          },
          success: _vm.ownerSelectOk,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
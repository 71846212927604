<template>
  <span>{{ label }}</span>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'PermissionLabelCellRenderer',
  data() {
    return {
      showDisableCheckbox: false
    }
  },
  created() {
    if (this.params != null && typeof this.params.isReadOnly == 'function') {
      this.showDisableCheckbox = this.params.isReadOnly();
    }
  },
  mounted() {
    if (this.showDisableCheckbox) {
      this.applyDisabledCheckboxStyle(this.params);
    }
  },
  beforeDestroy() {
    this.removeDisabledCheckboxStyle(this.params);
  },
  computed: {
    label() {
      if(!this.params) {
        return 'N/A';
      }
      const labelFunc = this.params.context.componentParent.label;
      if(labelFunc) {
        return labelFunc(this.params);
      }
      return this.params.label? this.params.label: 'N/A';
    }
  },
  watch: {
    showDisableCheckbox(newValue) {
      if (newValue) {
        this.applyDisabledCheckboxStyle(this.params);
      } else {
        this.removeDisabledCheckboxStyle(this.params);
      }
      
    }
  },
  methods: {
    applyDisabledCheckboxStyle(params) {
      const checkboxElement = params.eGridCell.querySelector('.ag-selection-checkbox');
      if (checkboxElement != null) {
        checkboxElement.classList.add('ag-checkbox-disabled');
      }
    },
    removeDisabledCheckboxStyle(params) {
      const checkboxElement = params.eGridCell.querySelector('.ag-selection-checkbox');
      if (checkboxElement != null) {
        checkboxElement.classList.remove('ag-checkbox-disabled');
      }
    },
    refresh(params) {
      this.params = params;
      if (this.params != null && typeof this.params.isReadOnly == 'function') {
        this.showDisableCheckbox = this.params.isReadOnly();
      }
    }
  }
});
</script>

<style lang="scss">
.folder-label-icon {
  font-size: 14px;
  position: absolute;
  top: 6px;
}
.folder-label-text {
  padding-left: 20px;
}

</style>
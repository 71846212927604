<template>
  <div :id="`SANDBOX_FORM_${id}`" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="md" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow"
      @hidden="modalCancel"
      scrollable
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <b-alert variant="danger" dismissible v-model="errorShow" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }} 
      </b-alert>

      <b-form-group :label="$t('sandbox.name')" label-for="name">
        <b-input-group>
          <b-form-input id="name" type="text"
            :data-vv-as="$t('sandbox.name')"
            data-vv-name="name"
            :maxlength="maxNameLength"
            data-vv-delay="500"
            v-model="name" 
            v-validate="{ required: true }"
            :readonly="isReadOnly"
            :autofocus="true"
            :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'name')"
            trim 
            @keydown.native="keydownHandler">
          </b-form-input>
        </b-input-group>
        
        <label v-if="createdDate !== null" class="mt-3">{{ $t('sandbox.created') }}</label>
        <b-input-group v-if="createdDate !== null">
          <b-form-input type="text"
            v-model="createdDate" 
            readonly>
          </b-form-input>
        </b-input-group>
        
        <b-row class="mt-3" v-if="sharing">
          <b-col>
            <label class="d-block">{{ $t('dataview.field.sharing') }}</label>
            <b-button class="sharing-members d-inline-block" size="sm" @click="editSharingMembers()">{{ $t('button.members') }}</b-button>
          </b-col>
          <b-col>
            <label class="d-block">{{ $t('sandbox.editing') }}</label>   
            <b-button class="sharing-members d-inline-block" size="sm" @click="editPermissions()">{{ $t('button.members') }}</b-button>
          </b-col>
          <b-col v-if="showOwner">
            <label class="d-block">{{ $t('sandbox.owner') }}</label>   
            <b-button class="sharing-members d-inline-block" size="sm" @click="editOwner()">{{ $t('button.owner') }}</b-button>
          </b-col>
        </b-row>
        
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('name') }}
        </b-form-invalid-feedback>
      </b-form-group>
        
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" disabled v-if="creating">
            <b-spinner small type="grow" />{{ $t(inProgressLabel) }}
          </b-button>
        <b-button size="sm" variant="success" @click="ok" v-else>{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <MembersModal :show.sync="showSharing" :members="sharingMembers.join(',')" :title="$t('dataview.select_members_title')" @success="membersSelectOk"/>
    <MembersModal :show.sync="showPermissions" :members="editingPermissions.join(',')" :title="$t('dataview.select_members_editing_title')" @success="permissionsSelectOk"/>
    <MembersModal :show.sync="showOwnerPrompt" :multiple="false" :members="owner" :title="$t('sandbox.select_owner_editing_title')" @success="ownerSelectOk"/>
    
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);
import { strRandom, EventBus } from '@/helpers';
import { sandboxService, userService } from '@/services';
import { fieldValidateUtil } from '@/script/helper-field-validate';


export default {
  name: 'SandboxModal',
  components: {
    MembersModal:  () => import('@/components/modal/MembersModal')
  },
  props: {
    id:        { type: [String, Number],   default: `SANDBOX_NEW_${strRandom(5)}` },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true },
    visibility: { type: String, default: 'private' },
    data:      { type: Object, default: null },
    nameEdit:   { type: Boolean, default: true },
    sharing:   { type: Boolean, default: false },
    showOwner: { type: Boolean, default: false }
  },
  data() {
    return {
      permissionName: 'SANDBOX',
      inProgressLabel: 'sandbox.progress.create',
      creating: false,
      modelInfo: null,
      alertMsg: null,
      state: {
        editable:            false,
        isSubmitting:        false,
        modalShow:           false
      },
      uuId:               null,
      name:               null,
      owner:              null,
      showOwnerPrompt:    false,
      showSharing: false,
      sharingMembers: [],
      showPermissions: false,
      editingPermissions: [],
      createdDate: null
    }
  },
  created() {
    this.fieldValidateUtil = fieldValidateUtil;
    this.originLocation = [];
    if(typeof this.id === 'string' && 
       this.id.indexOf('SANDBOX_NEW_') !== -1) {
      this.resetProperties();
    }
    else if (this.data) {
      this.name = this.data.name;
      this.uuId = this.data.uuId;
      this.owner = this.data.owner;
      this.createdDate = this.data.createdAt ? moment(this.data.createdAt).local().format('LLL') : null;
      this.sharingMembers = this.data.viewBy ? this.data.viewBy : [this.$store.state.authentication.user.uuId];
      this.editingPermissions = this.data.editBy ? this.data.editBy : [this.$store.state.authentication.user.uuId];
      userService.listv2({holder: this.sharingMembers}).then(response => {
        this.sharingMembers = response.data.map(u => { return u.uuId });
      })
      .catch(e => {
        console.log(e);//eslint-disable-line
      });
      userService.listv2({holder: this.editingPermissions}).then(response => {
        this.editingPermissions = response.data.map(u => { return u.uuId });
      })
      .catch(e => {
        console.log(e);//eslint-disable-line
      });
    }
  },
  mounted() {
    this.state.modalShow = this.show;
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
  },
  computed: {
    exists() {
      return this.id && !this.id.startsWith('SANDBOX_NEW_');
    },
    isReadOnly() {
      return !this.nameEdit || (!this.state.editable && this.readOnly);
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'name');
    },
    errorShow() {
      return this.alertMsg != null;
    },
    labelTitle() {
      return this.title? this.title: this.$t('sandbox.create');
    },
    maxNameLength() {
      return 100;
    }
  },
  watch: {
    show(newValue) {
      if(newValue != this.state.modalShow) {
        this.$validator.resume();
        this.state.modalShow = newValue;
        this.state.autoScheduleExpanded = false;
        this.alertMsg = null;
        this.creating = false;
        if(this.id.indexOf('SANDBOX_NEW_') !== -1) {
          this.resetProperties();
        }
        else if (this.data) {
          this.name = this.data.name;
          this.uuId = this.data.uuId;
        }
      }
    }
  },
  methods: {
    keydownHandler(event) {
      if (event.which === 13) {
        // The key pressed was the enter key
        this.ok();
      }
    },
    ok() {
      this.errors.clear();
      
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.alertMsg = null;
          if (!this.uuId || (typeof this.uuId === 'string' && this.uuId.startsWith('SANDBOX_NEW_'))) {
            this.sandboxSubmit('create', { name: this.name, viewBy: this.sharingMembers, editBy: this.editingPermissions });
          }
          else {
            this.sandboxSubmit('update', { uuId: this.uuId, name: this.name, viewBy: this.sharingMembers, editBy: this.editingPermissions, owner: this.owner });
          }
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
      
    },
    async sandboxSubmit(method, data) {
      this.inProgressLabel = method === 'create' ? 'sandbox.progress.create' : 'sandbox.progress.update';
      this.creating = true;
      await sandboxService[method](data).then((response) => {
        if (method === 'create') {
          this.$store.state.sandbox.value = response.data.jobClue.data;
          this.$store.state.sandbox.canEdit = true;
          EventBus.$emit('sandbox-added', this.name);
          this.$store.state.sandbox.name = this.name;
          this.$store.state.epoch.value = null; // clear the epoch if set
          EventBus.$emit('language-change');
        }
        this.$emit('update:show', false);
        this.$emit('success', { name: this.name, sandbox: response.data.jobClue.data });
      })
      .catch(e => {
        if (e.response.data.jobClue.clue === "Size_limit_exceeded") {
          this.alertMsg = this.$t('sandbox.error.number_limit_exceeded');
        }
        else if (e.response.data.jobClue.clue === "Not_available") {
          this.alertMsg = this.$t('sandbox.error.not_available',[e.response.data.jobClue.args[0].replace('sec', e.response.data.jobClue.args[1])]);
        }
      });
      this.creating = false;
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    resetProperties() {
      this.errors.clear();
      this.$validator.reset();
      this.name = null;
      this.uuId = `SANDBOX_NEW_${strRandom(5)}`;
      this.sharingMembers = [this.$store.state.authentication.user.uuId];
      this.editingPermissions = [this.$store.state.authentication.user.uuId];
    },  
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false)
    },
    editSharingMembers() {
      this.state.errorShow = false;
      this.showSharing = true;
    },
    editPermissions() {
      this.state.errorShow = false;
      this.showPermissions = true;
    },
    editOwner() {
      this.state.errorShow = false;
      this.showOwnerPrompt = true;
    },
    membersSelectOk(members) {
      if (members === "") {
        this.alertMsg = this.$t('dataview.error.members_empty');
        this.state.errorShow = true;
        return;
      }
      this.sharingMembers = members.split(',');
      
      for (var permission of this.editingPermissions) {
        if (!this.sharingMembers.find(p => p === permission)) {
          const index = this.editingPermissions.indexOf(permission);
          this.editingPermissions.splice(index, 1);
        }
      }
    },
    ownerSelectOk(owner) {
      if (owner === "") {
        this.alertMsg = this.$t('sandbox.error.owner_empty');
        this.state.errorShow = true;
        return;
      }
      this.owner = owner;
      if (!this.editingPermissions.includes(owner)) {
        this.editingPermissions.push(owner);
      }
      if (!this.sharingMembers.includes(owner)) {
        this.sharingMembers.push(owner);
      }
    },
    permissionsSelectOk(permissions) {
      if (permissions === "") {
        this.alertMsg = this.$t('dataview.error.permissions_empty');
        this.state.errorShow = true;
        return;
      }
      this.editingPermissions = permissions.split(',');
      const permissionArray = permissions.split(',');
      for (var permission of permissionArray) {
        if (permission !== this.userId &&
            !this.sharingMembers.find(p => p === permission)) {
          this.sharingMembers.push(permission);   
        }
      }
    }
  }
}
</script>
